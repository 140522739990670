import React, { useState, useCallback } from 'react';
import { Box, IconButton, Typography, Stack, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LuImagePlus } from "react-icons/lu";
import { notificationSuccess } from '../state/Action/NotificationAction';
import { useDispatch } from 'react-redux'

function FileUpload({ files, setFiles, text, setDeleteImage, deleteImage, singleImage }) {
    const [dragging, setDragging] = useState(false);
    const dispatch = useDispatch();

    const handleFileChange = (event) => {
        if (event.target.files.length === 0) {
            return;
        }

        if (event.target.files && event.target.files[0].size > 2 * 1024 * 1024) {
            dispatch(notificationSuccess({
                show: true,
                type: 'error',
                message: 'File size should be less than 5 MB'
            }))
            return;
        }

        if (singleImage) {
            setFiles([event.target.files[0]]);
        } else {
            if (files.length > 3) {
                dispatch(notificationSuccess({
                    show: true,
                    type: 'error',
                    message: 'You can upload only 4 images.'
                }))
                return;
            }
            setFiles([...files, ...Array.from(event.target.files)]);
        }
    };

    const handleDragEnter = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(true);
    }, []);

    const handleDragLeave = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
    }, []);

    const handleDragOver = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
    }, []);

    const handleDrop = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
        const droppedFiles = Array.from(event.dataTransfer.files);
        if (droppedFiles.length) {
            if (singleImage) {
                setFiles([event.target.files[0]]);
            } else {
                if (files.length > 3) {
                    dispatch(notificationSuccess({
                        show: true,
                        type: 'error',
                        message: 'You can upload only 4 images.'
                    }))
                    return;
                }
                setFiles([...files, ...droppedFiles]);
            }
        }
    }, [files]);
    const handleRemoveFile = (index, file) => {
        if (file?.id && !singleImage) {
            setDeleteImage([...deleteImage, file.id])
        }
        setFiles(files.filter((_, i) => i !== index));
    };


    const handleUploadClick = () => {
        // Upload logic goes here
    };

    return (
        <Box>
            {/* <Typography fontSize={{ xs: '15px', sm: '18px' }} fontWeight={500} mb={'2px'}>Product Images</Typography> */}
            <label htmlFor="raised-button-file" onClick={handleUploadClick} style={{ width: '100%' }}>
                <Box
                    sx={{
                        width: '100%',
                        height: '120px',
                        border: '1px dashed #c4c4c4',
                        borderRadius: '12px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: dragging ? '#e0e0e0' : '#f8f8f8',
                        fontSize: '14px',
                        fontWeight: '600',
                        cursor: 'pointer'
                    }}
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <Stack alignItems={'center'}>
                        <LuImagePlus style={{ fontSize: '30px', strokeWidth: '1.5', color: '#0F0F0F60' }} />
                        <Typography>Drop {text ? text : "Product"} Images or Browse</Typography>
                        <Typography color={'#0F0F0F60'} fontSize={'14px'}>Allowed *.jpeg, *.jpg, *.png,</Typography>
                        {/* <Typography color={'#0F0F0F60'} fontSize={'14px'}>max size of 3.1 MB</Typography> */}
                    </Stack>

                    <input
                        accept=".jpg, .jpeg, .png"
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={handleFileChange}
                    />
                </Box>
            </label>

            <Grid container spacing={'10px'} style={{ marginTop: '0px' }}>
                {files && files.length > 0 && files?.map((file, index) => (
                    <Grid item xs={singleImage ? 12 : 2.4} key={index}>
                        <Box key={index} sx={{ width: '100%', height: '100px', position: 'relative', border: '1px solid #e3e3e3', borderRadius: '10px' }}>
                            <IconButton
                                style={{ position: 'absolute', right: 0, top: 0, zIndex: '4' }}
                                onClick={() => handleRemoveFile(index, file)}
                            >
                                <DeleteIcon sx={{ fontSize: '18px', color: '#fff' }} />
                            </IconButton>
                            <Box className='background_gradient'></Box>

                            {singleImage && singleImage === true ? (
                                file != undefined &&
                                <img
                                    src={file?.image || URL.createObjectURL(file)} // Use file?.image if available, otherwise create a URL
                                    alt={`preview ${index}`}
                                    style={{ width: '100%', height: '100%', borderRadius: '10px', objectFit: 'cover' }}
                                />
                            ) : (
                                // Render based on singleImage condition being false
                                // Check if file and file.id exist before rendering
                                <>
                                    {file && file.id ? (
                                        // Render image using file.image if available
                                        <img
                                            src={file.image}
                                            alt={`preview ${index}`}
                                            style={{ width: '100%', height: '100%', borderRadius: '10px', objectFit: 'cover' }}
                                        />
                                    ) : (
                                        // Render image using URL.createObjectURL(file) if file exists
                                        file !== undefined && (
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt={`preview ${index}`}
                                                style={{ width: '100%', height: '100%', borderRadius: '10px', objectFit: 'cover' }}
                                            />
                                        )
                                    )}
                                </>
                            )}

                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box >
    );
}

export default FileUpload;