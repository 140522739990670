import React, { useState, useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./components/Layout/Layout";
import { ThemeProvider } from "@mui/material";
import { theme } from "./helper/Theme";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Notification from "./components/common/Notification/Notification";
import Login from "./pages/login/Login"
import PrivateRoute from './Routes/PrivateRoute';
import NoDataFound from './pages/NoDataFound/NoDataFound';
import { loadUser } from "./Service/auth.service";
import ImageList from './pages/imageList';
import { loginUserApi, logoutApi } from "./state/Action/authAction";

function App() {
  const location = useLocation();
  const { pathname } = location;
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { show } = useSelector(state => state?.root?.notification);
  const { user } = useSelector((state) => state?.root?.auth);
  const { auth } = useSelector((state) => state?.root);
  const isAuthenticate = useSelector((store) => store?.root?.auth?.isAuthenticate);


  useEffect(() => {
    if (pathname == "/login" && user && user?.name && user?.name) {
      Navigate("/");
    }
  }, [user, pathname]);

  useEffect(() => {
    if (pathname == "/" && !isAuthenticate) {
      Navigate("/login");
    }
  }, [pathname, isAuthenticate]);

  const getUsetData = async (data) => {
    let res = await loadUser(data);
    if (res?.status) {
      dispatch(loginUserApi(res?.data));
    }
  };


  useEffect(() => {
    if (pathname !== "/login") {
      const token = localStorage.getItem("token");
      const payload = {
        token: token,
      };
      getUsetData(payload);
    }

  }, []);



  const [showNotification, setShowNotification] = useState({
    show: false,
    type: '',
    message: ''
  });
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: '', head: '',
    isEdit: false,
    isView: false,
    id: ''
  });

  const pages = [
    { path: "/", element: <ImageList />, name: "dashboard" },
  ];

  return (
    <ThemeProvider theme={theme}>
      <div className="background_Image" >
        <Routes>
          <Route path="/login" element={<Login />} />
          {pages?.map((page, index) => {
            const { name, path, element } = page;
            return path ? <Route key={index} exact path={`/${path}`} element={
              <PrivateRoute>
                <Layout>{element}</Layout>
              </PrivateRoute>
            }
            /> : <Route path="*" element={<NoDataFound />} />;
          }
          )}
        </Routes>
      </div>

      {showNotification && showNotification.show ? <Notification show={showNotification} setShow={setShowNotification} /> : null}
    </ThemeProvider>
  );
}

export default App;
