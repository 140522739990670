import * as React from "react";
import { styled } from "@mui/material/styles";
import { Bell, Setting, ProfileIcon, Notificationuser } from "../Constants";
import { Box, Divider, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { useStyles } from "../../helper/Theme";
import { AccountCircleIcon, LogoutIcon } from "../../assets/icons/Icons";
import LogoutModal from "../../components/common/modal/logoutModal";
import PasswordIcon from "@mui/icons-material/Password";
import { Stack } from "@mui/material";
import CommonModal from "../common/Modal";
import Notification from "../common/Notification/Notification";
import { logoutApi } from "../../state/Action/authAction";
import { useDispatch, useSelector } from "react-redux";

const MyComponent = styled("div")({
  borderRadius: "10px",
  border: "1px solid #FFF",
  background: "#F6F8F9",
  boxShadow: "2px 3px 10px 0px rgba(0, 0, 0, 0.15)",
  display: "flex",
});

const Header = () => {
  const theme = useTheme();
  const classes = useStyles();
  const medium = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = useSelector((state) => state?.root?.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [myprofile, setMyProfile] = useState(null);
  const [profileIcon, setProfileIcon] = useState(false);
  const [userId, setUserId] = useState("");
  const [show, setShow] = useState({
    show: false,
    type: "",
    message: "",
  });

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });

  const handelLogout = () => {
    setIsModalOpen({
      open: false,
      currentComponent: "",
      para: '',
      head: '',
      id: ''
    });
    localStorage.removeItem('token');
    dispatch(logoutApi());

    navigate('/login');
  }


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = (event) => {
    setMyProfile(event.currentTarget);
  };

  const handleCloseProfile = () => {
    setMyProfile(null);
  };

  return (
    <>
      <Grid item></Grid>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "15px",
        }}
      >
        {/* <MyComponent
          sx={{ padding: { xs: '8px' }, cursor: 'pointer' }}
          onClick={handleClick}
        >
          <img src={Bell} alt='Bell' width='25' />
        </MyComponent> */}


        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { md: "20px", lg: "15px" },
            cursor: "pointer",
          }}
          onClick={handleClickProfile}
        >
          {medium ? (
            ""
          ) : (
            <>
              <Divider orientation="vertical" flexItem />
              <div> {user?.name} </div>
            </>
          )}
          <img
            src={profileIcon == "" ? ProfileIcon : profileIcon}
            alt="ProfileIcon"
            width={40}
          />
        </Box>
        <Menu
          anchorEl={myprofile}
          open={Boolean(myprofile)}
          onClose={handleCloseProfile}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >

          <MenuItem key="logout">
            <LogoutModal
              handelLogout={handelLogout}
              onClick={() => {
                setIsModalOpen({
                  open: true,
                  currentComponent: "logout",
                  id: userId,
                });
              }}
            />
          </MenuItem>
        </Menu>
      </Grid>
      <CommonModal
        show={show}
        modalOpen={isModalOpen}
        setShow={setShow}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "", isEdit: false })
        }
      />
      <Notification show={show} setShow={setShow} />
    </>
  );
};

export default Header;
