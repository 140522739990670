import {
    Box,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Avatar,
    Popover,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Heading from "../../components/Heading";
import { FaEdit, FaEye, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../components/common/Button";
import CommonModal from "../../components/common/Modal";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import Pagination from "../../components/common/Table/Pagination";
import TableSearch from "../../components/common/Table/TableSearch";
import { useFormik } from "formik";
import { uploadImage, getImages, updateImage } from "../../Service/image.service";

const ImageList = () => {
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [files, setFiles] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentImage, setCurrentImage] = useState("");
    const [isModalOpen, setIsModalOpen] = useState({
        open: false,
        currentComponent: "",
        para: "",
        head: "",
        id: "",
    });
    const [isEdit, setIsEdit] = useState(false);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const [show, setShow] = useState({
        show: false,
        type: "",
        message: "",
    });

    const dispatch = useDispatch();
    const [imageData, setImageData] = useState([]);

    const getImagesData = async (page, rowsPerPage, search) => {
        try {
            const res = await getImages(page, rowsPerPage, search);
            if (res.status) {
                setImageData(res.data);
                setTotalData(res.total);
                setTotalPages(res.totalPages / rowsPerPage);
            } else {
                setImageData([]);
                setTotalData(0);
                setTotalPages(0);
            }
        }
        catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        getImagesData(page, rowsPerPage, search);
    }, [page, rowsPerPage, search]);

    const formik = useFormik({
        initialValues: {
            title: "",
        },
        onSubmit: async (values) => {
            if (files.length === 0) {
                dispatch(
                    notificationSuccess({
                        show: true,
                        type: "error",
                        message: "Please select image",
                    })
                );
                return;
            }
            const formData = new FormData();
            formData.append("title", values.title);
            formData.append("file", files[0]);
            const res = isEdit ? await updateImage(formData, isModalOpen.id) : await uploadImage(formData);
            if (res.status) {
                dispatch(
                    notificationSuccess({
                        show: true,
                        type: "success",
                        message: res.message,
                    })
                );
                setIsModalOpen({ open: false, currentComponent: "" });
                getImagesData(page, rowsPerPage, search);
                formik.resetForm();
            } else {
                dispatch(
                    notificationSuccess({
                        show: true,
                        type: "error",
                        message: res.message,
                    })
                );
            }
        },
    });

    const handleClick = (event, image) => {
        setAnchorEl(event.currentTarget);
        setCurrentImage(image);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentImage("");
    };


    return (
        <>
            <Stack gap={2}>
                <Box>
                    <Heading head={"Sketch Images"} />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                        alignItems: "center",
                    }}
                >
                    <Box>
                        <TableSearch
                            search={search}
                            setSearch={setSearch}
                            setPage={setPage}
                        />
                    </Box>
                    <Box display={"flex"} gap={1}>
                        <Tooltip title="Click to add new sketch">
                            <CommonButton
                                buttonName="Sketch"
                                onClick={() => {
                                    setIsModalOpen({
                                        open: true,
                                        currentComponent: "createSketch",
                                        id: "",
                                    });

                                }}
                                startIcon={<FaPlus />}
                            />
                        </Tooltip>

                    </Box>
                </Box>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Image</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell sx={{ textAlign: "end" }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {imageData.length === 0 ? (
                                // Display "No data available" message when data is empty
                                <TableRow>
                                    <TableCell colSpan={13} align="center">
                                        No data available
                                    </TableCell>
                                </TableRow>
                            ) : (
                                // Display actual data
                                imageData.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        <TableCell>{row?.id}</TableCell>
                                        <TableCell>{row?.name}</TableCell>
                                        <TableCell>
                                            {/* <img
                                                src={row?.url}
                                                alt={row?.title}
                                                style={{ width: "50px", height: "50px" }}
                                            /> */}
                                            <Box display={"flex"} gap={1}>
                                                <Avatar
                                                    sx={{ width: 50, height: 50 }}
                                                    src={row?.url}
                                                    onClick={(e) => { handleClick(e, row?.url); e.stopPropagation() }} // Open popover on click
                                                />
                                                {/* {row.productName} */}
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            {new Date(row?.created_at).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell onClick={(e) => e.stopPropagation()}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    gap: 1,
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <Tooltip title="Edit Details">
                                                    <IconButton
                                                        onClick={() => {
                                                            setIsModalOpen({
                                                                open: true,
                                                                currentComponent: "createSketch",
                                                                id: row?.id,
                                                            });

                                                        }}
                                                        size="small"
                                                    >
                                                        <FaEdit />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                >
                    <Box
                        component="img"
                        src={currentImage}
                        alt="Design Image"
                        sx={{
                            width: 300, // Adjust the width as needed
                            height: "auto",
                            padding: 1,
                        }}
                    />
                </Popover>
            </Stack>
            {imageData !== null && (
                <Pagination
                    totalData={totalData} // Total number of data items
                    page={page} // Current page number
                    setPage={setPage} // Function to update current page number
                    rowsPerPage={rowsPerPage} // Number of rows per page
                    setRowsPerPage={setRowsPerPage} // Function to update number of rows per page
                />
            )}

            <CommonModal
                show={show}
                modalOpen={isModalOpen}
                formik={formik}
                setShow={setShow}
                files={files}
                setFiles={setFiles}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                handleClose={() =>
                    setIsModalOpen({ open: false, currentComponent: "", isEdit: false })
                }
            />
        </>
    )
}

export default ImageList;