import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from "@mui/material";
import CommonButton from "../Button";
import Input from "../Input";
import { CloseIcon } from "../../Constants";
import FileUpload from "../../../components/FileUpload";
import { getImageDetails } from "../../../Service/image.service";

const Create_Image = ({ handleClose, handelClick, formik, files, setFiles, modalOpen, isEdit, setIsEdit }) => {

    const handleGetData = async (id) => {
        try {
            const res = await getImageDetails(id);
            if (res.status) {
                console.log("res", res);
                formik.setFieldValue("title", res?.data?.name);
                setFiles([{ id: res?.data?.id, image: res?.data?.url }]);
                setIsEdit(true);
            } else {
                console.log("error", res);
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    useEffect(() => {
        if (modalOpen && modalOpen.id) {
            handleGetData(modalOpen.id)
        }
    }, [modalOpen]);

    return (
        <>
            <DialogTitle>Create Image</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            ><CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ width: "500px" }}>
                <Grid container gap={2} paddingY={1}>
                    <Grid item xs={12}>
                        <Input
                            labelinput='Title'
                            placeholder='Enter Title'
                            // disabled={isView}
                            formik={formik}
                            name='title'
                            maxLength={50}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize={{ xs: '13px', sm: '16px' }} fontWeight={500} mb={'2px'}>Image</Typography>
                        <FileUpload key={1} files={files} setFiles={setFiles} singleImage={true} text={"Sketch"} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton onClick={handleClose} buttonName="Cancel" />
                <CommonButton onClick={formik.handleSubmit} buttonName="Save" />
            </DialogActions>
        </>
    );
}

export default Create_Image;