import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  Vector10,
  Dashboard,
  Vector4,
  Vector6,
  Vector1,
  Vector3,
  Vector7,
  Vector5,
  Vector2,
} from "../Constants"; // Import your sub-item icons
import List from "@mui/material/List";
import { Box, ListItemText, Collapse } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess"; // Chevron up icon
import ExpandMore from "@mui/icons-material/ExpandMore"; // Chevron down icon
import logo from '../../assets/images/sidebar/Logo.png'
import { useSelector } from "react-redux";
// import { IsRead } from "../../helper/handlePermission";



const Sidebar = ({ handleDrawerToggle, drawerWidth }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Separate state for each dropdown
  const [openSettings, setOpenSettings] = useState(false);
  const [openInventory, setOpenInventory] = useState(false);

  const handleToggle = (menu) => {
    if (menu === "Settings") {
      setOpenSettings(!openSettings);
    } else if (menu === "Inventory") {
      setOpenInventory(!openInventory);
    }
  };
  const { user } = useSelector((state) => state?.root?.auth);

  const SidebarList = [
    {
      name: "Sketches",
      icon: Vector10,
      activeIcon: Dashboard,
      path: "/",
      show: true,
      visible: true,

    },

  ];
  console.log("sidebar", SidebarList)
  return (
    <Box width={drawerWidth}>
      <List
      // sx={{
      //   padding: "24px 24px 15px 24px",
      // }}
      >
        <img
          src="https://www.oilpixel.com/ast/themes/oilpixel/images/logo.svg"
          alt="main_logo"
          style={{
            width: "200px",
          }}
        />
      </List>
      <List>
        {SidebarList.map((data, index) => {
          if (!data.visible) {
            return null;
          } else {


            return <div key={index}>
              <ListItem disablePadding sx={{ marginBottom: "8px" }}>
                <ListItemButton
                  onClick={() => {
                    if (data.subItems) {
                      handleToggle(data.name); // Toggle open/close for items with subItems
                    } else {
                      navigate(data.path);
                    }
                    handleDrawerToggle(); // Optionally close the drawer
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 30,
                      width: "13px",
                      height: "17px",
                    }}
                  >
                    <img
                      src={
                        location.pathname === data.path
                          ? data.activeIcon
                          : data.icon
                      }
                      alt=""
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={data.name}
                    sx={{
                      color:
                        location.pathname === data.path ? "var(--primary)" : "",
                      fontSize: "15px",
                    }}
                  />

                </ListItemButton>
              </ListItem>

            </div>
          }
        })}
      </List>
    </Box>
  );
};

export default Sidebar;
