import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './NoPageFound.css'; // Make sure to create and style this CSS file
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const NoPageFound = () => {
    const navigate = useNavigate();
    const { user } = useSelector(state => state.root.auth);

    console.log(user);
    return (
        <div className="no-page-found">
            <h1>404</h1>
            <p>Oops! The page you are looking for does not exist.</p>
            {/* <Link to="/" className="home-link">Go back to Home</Link> */}
            <div className="home-link" onClick={() => {
                navigate("/login");
            }}>
                Go back to Home</div>
        </div>
    );
};

export default NoPageFound;
