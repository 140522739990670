import { LOGIN_SUCCESS, LOGOUT, EXPIRED } from '../Action-type/authActionType';


export const loginUserApi = (data) => async (dispatch) => {
  console.log("data */*/*/*/*/*/", data);
  dispatch({ type: LOGIN_SUCCESS, payload: data });
}

export const logoutApi = () => async (dispatch) => {
  dispatch({ type: LOGOUT });
}
export const sessionExpired = () => async (dispatch) => {
  dispatch({ type: EXPIRED });
}


