import { get, post, deleteRequest, put } from "../web.request";

export const uploadImage = async (data) => {
    return await post(`/upload`, data);
}

export const deleteImage = async (data) => {
    return await deleteRequest(`/upload`, data);
}

export const getImages = async () => {
    return await get(`/images`);
}

export const getImageDetails = async (id) => {
    return await get(`/image/${id}`);
}

export const updateImage = async (data, id) => {
    return await put(`/update/${id}`, data);
}