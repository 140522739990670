import axios from "axios";

axios.defaults.withCredentials = false;


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // baseURL: "http://localhost:3001/api/v1/",
    crossDomain: false,
});

// Interceptor to update headers with the latest token
const updateTokenInterceptor = axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers["x-auth-token"] = `${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Function to remove the token interceptor
const removeTokenInterceptor = () => {
    axiosInstance.interceptors.request.eject(updateTokenInterceptor);
};

export { axiosInstance, removeTokenInterceptor };
