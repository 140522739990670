import * as React from "react";
import Dialog from "@mui/material/Dialog";

import DeleteModal from "./modal/logoutModal";
import { Update } from "@mui/icons-material";
import UpdateStatus from "./modal/UpdateStatus";
import Delete_Modal from "./modal/Delete_Modal";
import Delete from "./modal/Delete";
import Conformation from "./modal/Conformation";
import Create_Image from "./modal/createimage";


export default function CommonModal({
  handleClose,
  modalOpen,
  setShow,
  show,
  handelClick,
  files,
  setFiles,
  formik,
  isEdit,
  setIsEdit
}) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Common_modal"
      open={modalOpen.open}
      className={modalOpen.class}
    >

      {modalOpen.currentComponent === "logout" && (
        <DeleteModal
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
        />
      )}
      {modalOpen.currentComponent === "updatestatus" && (
        <UpdateStatus
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
        />
      )}


      {modalOpen.currentComponent === "deletemodal" && (
        <Delete_Modal
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}

        />
      )}

      {modalOpen.currentComponent === "createSketch" && (
        <Create_Image
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
          files={files}
          setFiles={setFiles}
          formik={formik}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}


      {['conform'].includes(modalOpen.currentComponent) && (
        <Conformation handleClose={handleClose} modalOpen={modalOpen} handelClick={handelClick} />
      )}
      {['delete', 'status', 'changepassword'].includes(modalOpen.currentComponent) && (
        <Delete handleClose={handleClose} modalOpen={modalOpen} handelClick={handelClick} />
      )}
    </Dialog>
  );
}
